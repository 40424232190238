<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" persistent overlay-opacity="0.8" scrollable>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              {{ $t("favoritesPage.addDialogTitle") }}
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn @click="$emit('close')" icon><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text style="font-size: 1.3rem">
          <v-list v-if="!itemsLoading">
            <v-list-item-group v-model="containers_ids" multiple active-class="selected">
              <v-list-item
                :ripple="false"
                class="my-4"
                :class="{ normal: !selected(c.id) }"
                v-for="(c, i) in collections"
                :value="c.id"
                :key="i"
              >
                <v-list-item-avatar class="grey lighten-1" rounded="sm">
                  <v-img :src="itemPic(images(c)[0])" aspect-ratio="1"></v-img>
                </v-list-item-avatar>
                <v-list-item-title>{{ c.name }}</v-list-item-title>
                <v-list-item-action
                  ><v-avatar v-show="selected(c.id)" size="40" color="black" rounded="xl"
                    ><v-icon color="white" dark>mdi-check</v-icon></v-avatar
                  ></v-list-item-action
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <div v-if="itemsLoading" class="">
            <v-row
              ><v-progress-circular
                indeterminate
                class="mx-auto"
                size="50"
              ></v-progress-circular
            ></v-row>
          </div>
        </v-card-text>

        <v-card-actions class="mx-2 py-4">
          <v-btn
            @click="addToCollections(false)"
            color="black"
            light
            :loading="loading"
            class="text-capitalize px-6"
            elevation="0"
            style="font-size: 1.1rem; letter-spacing: 0px; color: white"
          >
            {{ $t("favoritesPage.save") }}
          </v-btn>
          <v-btn
            text
            @click="addToCollections(true)"
            class="text-capitalize"
            style="font-size: 0.9rem; letter-spacing: 0px"
          >
            {{ $t("favoritesPage.skip") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["dialog", "item"],
  data() {
    return {
      containers_ids: [],
      loading: false,
      itemsLoading: false,
    };
  },
  computed: {
    ...mapState({
      collections: (state) => state.favoritesModule.collections,
      defaultCount: (state) => state.favoritesModule.defaultCount,
    }),
  },
  created() {
    this.getCollections(false);
  },

  methods: {
    addToCollections(skip) {
      if (skip) this.containers_ids = null;
      else if (this.containers_ids.length == 0) {
        this.deleteFromAll();
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("favoritesModule/addToCollections", {
          id: this.item.id,
          body: {
            containers_ids: this.containers_ids,
          },
        })
        .then(() => {
          this.$emit("done");
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteFromAll() {
      this.loading = true;
      this.$store
        .dispatch("itemsModule/removeFromFavorites", { id: this.item.id })
        .then(() => {
          this.$emit("done");
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCollections() {
      this.itemsLoading = true;
      this.$store
        .dispatch("favoritesModule/getCollections", {
          filters: {
            item_id: this.item.id,
          },
        })
        .then(() => {
          this.collections.forEach((e) => {
            if (e.meta?.is_attached) this.containers_ids.push(e.id);
          });
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },
    images(collection) {
      return collection.meta?.images;
    },
    selected(n) {
      return this.containers_ids.includes(n);
    },
    itemPic(image) {
      console.log(image ?? "null");
      return `https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${
        image?.url ?? "null"
      }`;
    },
  },
};
</script>

<style>
.normal {
  outline: 1px solid rgb(71, 71, 71);
  border-radius: 7px;
}
.selected {
  outline: 3px solid black !important;
  border-radius: 7px;
}
</style>
